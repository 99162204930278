import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuex from 'vuex';
Vue.use(Vuex);
// 暂时不挂载在全局
import http from './utils/http';
Vue.prototype.$http = http;
Vue.use(ElementUI);
import VScaleScreen from "v-scale-screen";
Vue.use(VScaleScreen);
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});