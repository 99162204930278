<!--
 * @Author: wlliu 1731635652
 * @Date: 2024-09-10 17:44:36
 * @LastEditors: wlliu 1731635652
 * @LastEditTime: 2024-09-13 11:14:21
 * @FilePath: \wh-gw\src\components\Footer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bot">
    <div class="bot_wrap">
      <img src="@/assets/img/logo.png">
      <div class="bot_content">
          <div class="content_cate">
            <h3>关于我们</h3>
            <p>公司简介</p>
            <p>发展历程</p>
            <p>荣誉资质</p>
          </div>
          <div class="content_cate">
            <h3>产品体系</h3>
            <p>调度管理与辅助决策</p>
            <p>电网运行分析</p>
            <p>新能源运行管理</p>
            <p>电网人工智能</p>
            <p>使用案例</p>
          </div>
          <div class="content_cate">
            <h3>新闻动态</h3>
            <p>新闻动态</p>
          </div>
          <div class="content_cate">
            <h3>联系我们</h3>
            <p>人才招聘</p>
          </div>
      </div>
    </div>

    <el-divider></el-divider>
    <div class="bottom" style="margin-top: -3%;">
      <span>网站备案号：</span>
    <el-link href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2022013556号-1</el-link>
    <span>网公网安备：</span>
    <el-link href="https://beian.miit.gov.cn/" target="_blank">皖ICP备2022013556号</el-link>
    </div>
    
  </div>
</template>

<script>

export default {
  name: 'Footer',
  data() {
    return {

    };
  },
  methods: {
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.bot {
  color: #2C2C2C;
  width: 100%;
  height: 340px;
  padding:36px 60px;
  background: #F2F3F5;
  box-sizing: border-box;
  font-size: 14px;
  .bot_wrap{
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    .bot_content{
      display: flex;
      justify-content: space-around;
      align-items: self-start;
      .content_cate{
        width: 160px;
      }
    }
    
  }
  .bottom{
    display: flex;
    align-items: center;
    span{
      margin-left: 26px;
    }
  }
}
</style>