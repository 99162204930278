
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    redirect: '/index',
  },
    //首页
  {
    path: '/index',
    name:'首页',
    component: () => import('@/views/Home/index')
  },
    //关于我们
  {
    path: '/about',
    name:'关于我们',
    component: () => import('@/views/About/index'),
  },
  {
    path: '/CompanyProfile',
    name:'公司简介',
    component: () => import('@/views/About/index'),
  },
  //产品体系
  {
    path: '/products',
    name:'产品体系',
    component: () => import('@/views/Products/index')
  },
  {
    path: '/SchedulingManagement',
    name:'调度管理与辅助决策',
    component: () => import('@/views/Products/index')
  },
  //新闻动态
  {
    path: '/news',
    name:'新闻动态',
    component: () => import('@/views/News/index')
  },
  {
    path: '/whConsulting',
    name:'网华咨询',
    component: () => import('@/views/News/index')
  },
  //联系我们
  {
    path: '/contact',
    name:'联系我们',
    component: () => import('@/views/Contact/index')
  },
  {
    path: '/tRecruitment',
    name:'人才招聘',
    component: () => import('@/views/Contact/tRecruitment')
  },
  // {
  //   path: '/contentdata',
  //   name:'岗位信息',
  //   component: () => import('@/views/Contact/contentdata')
  // },
  //登录
  {
    path: '/login',
    name:'登录',
    component: () => import('@/views/Login/index')
  },
  //管理
  {
    path: '/management',
    name:'后台管理',
    component: () => import('@/views/Management/index'),
    children:[
      //首页
      {
        path: '/management/banner/:title',
        name:'首页banner',
        component: () => import('@/views/Management/banner')
      },
      //关于我们
      {
        path: '/management/aboutUs/:title',
        name:'关于我们',
        component: () => import('@/views/Management/aboutUs')
      },
      //调度管理与辅助决策
      {
        path: '/management/SchedulingManagement/:title',
        name:'调度管理与辅助决策',
        component: () => import('@/views/Management/Product')
      },
      //电网运行分析
      {
        path: '/management/PowerAnalysis/:title',
        name:'电网运行分析',
        component: () => import('@/views/Management/Product')
      },
      //新能源运行管理
      {
        path: '/management/energyManagement/:title',
        name:'新能源运行管理',
        component: () => import('@/views/Management/Product')
      },
      //电网人工智能
      {
        path: '/management/ai/:title',
        name:'电网人工智能',
        component: () => import('@/views/Management/Product')
      },
      //使用案例
      {
        path: '/management/UseCase/:title',
        name:'使用案例',
        component: () => import('@/views/Management/Product')
      },
      //新闻动态
      {
        path: '/management/news/:title',
        name:'新闻动态',
        component: () => import('@/views/Management/news')
      },
      //联系我们
      {
        path: '/management/contactUs/:title',
        name:'联系我们',
        component: () => import('@/views/Management/contactUs')
      },
      //人才招聘
      {
        path: '/management/jobDisplay/:title',
        name:'人才招聘',
        component: () => import('@/views/Management/jobDisplay')
      },
    ]
  },
]
let originPush =  VueRouter.prototype.push;  //备份原push方法
 
VueRouter.prototype.push = function (location, resolve, reject){
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    }else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, ()=>{}, ()=>{}); 
    }
}
const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
})

export default router
