// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
 
Vue.use(Vuex);
 
export default new Vuex.Store({
  state: {
    username: '',
    password: '',
    token: '',
  },
  mutations: {
    updateToken(state,num) {
      state.token = num;
    }
  }
});
