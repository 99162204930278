import axios from 'axios';

// 创建 axios 实例
const service = axios.create({
  // baseURL: 'http://192.168.10.239:8888',
  // baseURL: 'http://10.10.30.120:18888',
  baseURL: 'http://101.43.26.228:80', // 打包
  // baseURL: 'http://192.168.10.250:18888',//线上
  timeout: 5000000, // 请求超时时间
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    // 例如：添加 token
    const token = localStorage.getItem('token');
    if (token && !config.url.includes('/api')) {
      // config.headers['Authorization'] = `Bearer ${token}`;
      config.headers['Authorization'] = token;
      config.headers['token'] = token;
    }
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  response => {
    // 对响应数据做些什么
    return response.data;
  },
  error => {
    // 对响应错误做些什么
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理未授权
          break;
        case 403:
          // 处理禁止访问
          break;
        case 404:
          // 处理资源未找到
          break;
        case 500:
          // 处理服务器内部错误
          break;
        default:
          // 其他错误处理
          break;
      }
    }
    return Promise.reject(error);
  }
);

// 封装 get 方法
const get = (url, params = {}, customHeaders = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const finalHeaders = { ...defaultHeaders, ...customHeaders };
  return service.get(url, {
    params,
    headers: finalHeaders,
  });
};

// 封装 post 方法
const post = (url, data = {}, customHeaders = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const finalHeaders = { ...defaultHeaders, ...customHeaders };
  return service.post(url, data, {
    headers: finalHeaders,
  });
};

const download = (url, params = {}, customHeaders = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const finalHeaders = { ...defaultHeaders, ...customHeaders };
  return service.get(url, {
    params,
    headers: finalHeaders,
    responseType: 'blob', // 设置响应类型为 blob
  });
};

// 导出封装的方法
export default {
  get,
  post,
  download
};